<template>
    <div>
        <loading-vue v-if="processing == true" />
        <div class="lg:relative">
            <div class="lg:my-3 lg:mx-3 md:m-6 mx-3 mt-3">
                <div class="grid grid-cols-4 gap-2 pl-3 ">
                    <div class="col-span-3">
                        <div class="flex items-center ">
                            <span class="w-full   cursor-default">
                                Rcode : {{ user.rcode }}
                            </span>
                            <input class="cursor-default hidden" v-on:focus="$event.target.select()" ref="rcodecoty"
                                readonly :value="user.rcode" />
                        </div>
                    </div>
                    <div class="col-span-1">
                        <div class="relative text-right text-md  ">
                            <button @click="copy">
                                <img src="../../../assets/image/copy-icon.png" alt=""
                                    class="absolute inset-y-0 right-0 copy-icon" />
                            </button>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-4 gap-2 pl-3 mt-1">
                    <div class="col-span-2 top-5">
                        <div class=" flex items-center  ">
                            <span class="w-full   cursor-default">
                                ${{ this.avlBalance }}.00
                            </span>
                            <button class="rap-btn  text-sm cursor-pointer ml-5" @click="withdrawlpop = true"
                                :disabled="processing">
                                Withdraw
                            </button>
                        </div>
                    </div>
                </div>
                <transition name="bounce">
                    <div class="absolute shadow-lg text-center top-0 right-0 left-0 m-auto w-full h-full bg-green-600 bg-opacity-50 z-50"
                        v-if="withdrawlpop">
                        <div
                            class="absolute top-10 shadow-lg text-center right-0 left-0 m-auto bg-white w-11/12 h-44 p-2 rounded-xl">
                            <p class="mt-4">Are you sure you want to withdrawal ?</p>
                            <div class="h-3/4 relative">
                                <button class="w-30 absolute bottom-2 left-2 rap-btn "
                                    @click="withdrawlpop = false">
                                    Cancel
                                </button>
                                <button class="w-30 absolute bottom-2 right-2 rap-green-btn  text-black"
                                    @click="withdrawlMoney">
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <div class="lg:m-2 md:m-6 mx-3 mt-3">
                <div class="mt-4">
                    <span class=" text-black text-xl pl-3"> Rewards history</span>
                    <!-- Transactions -->
                    <div v-for="(data, index) in history" :key="index" class="px-2">
                        <div class="border-b-2 border-black mx-3 m-5" v-if="currentRouteName != 'TopRappersWithState'">
                        </div>
                        <div v-if="data.artists" class="justify-between mt-2 h-12 w-full">
                            <div class="w-full ml-1 pl-2 ">
                                R-code used by {{ data.artists.member_name }}
                            </div>
                            <div class="pt-1 pl-3 text-left">
                                <!-- {{ data.rcode }} -->
                                <span class="block text-xs">
                                    Rewarded on {{ setDate(data.created_at) }} - ${{ data.rcode_rewards }}
                                </span>
                            </div>
                            <!-- <div class="text-green-600 w-24 pt-3 text-xl pr-1 align-middle"> +${{ data.rcode_rewards }}
                              </div> -->
                        </div>
                        <div class="mt-4" v-else>
                            <h1 class="text-xl text-center"></h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from "../../../api/api";
import LoadingVue from "../../Shared/Loading.vue";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
export default {
    name: "RcodeHistory",
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            withdrawlpop: false,
            time: null,
            processing: false,
        };
    },
    components: {
        LoadingVue,
    },
    methods: {
        withdrawlMoney() {
            this.withdrawlpop = false;
            this.processing = true;
            let url = "request-payout";

            API.post(url)
                .then(() => {
                    this.processing = false;
                    this.rcodehistory();
                })
                .catch(() => {
                    this.processing = false;
                });
        },
        setDate(time) {
            time = time.split("T")[0];
            let date = new Date(time);
            return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
        },

        rcodehistory() {
            this.processing = true;
            let url = "rcode-reward-history";
            API.get(url).then((response) => {
                this.processing = false;
                let data = response.data;

                this.$store.commit("Rcodehistory/SET_AVLBALANCE", data.total_rewards);
                this.$store.commit("Rcodehistory/SET_HISTORY", data.rewards.data);

                this.avlBalance = data.total_rewards;

                console.log(this.avlBalance);
            });
        },
        copy() {
            var text = this.$refs.rcodecoty.value;
            //   document.execCommand("copy");
            navigator.clipboard.writeText(text);
            this.toast.success("Rcode copied successfully");
        },
    },
    computed: {
        avlBalance() {
            return this.$store.getters["Rcodehistory/avlBalance"];
        },
        history() {
            return this.$store.getters["Rcodehistory/history"];
        },
        ...mapGetters({ user: "User/user" }),
    },
    created() {
        this.rcodehistory();
    },
};
</script>
